.cx-servicebanner {
    position: relative;
    padding: 55px 0;
}
.cx-servicebanner__overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
}
.cx-servicebanner__image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    object-fit: cover;
}
.cx-servicebanner__wrap {
    position: relative;
    z-index: 3;
    margin: 0 auto;
    text-align: center;
    padding: 20px;
    max-width: 60%;
    -webkit-box-shadow: 5px 5px 25px 1px rgb(0 0 0);
    box-shadow: 5px 5px 25px 1px rgb(0 0 0);
}
.cx-servicebanner__content-subtitle {
    color: var(--cx-color);
    margin-bottom: 10px;
    font-size: 14px;
}
.cx-servicebanner__content-wrap ul {
    padding-left: 0;
    list-style-type: none; 
}
.cx-servicebanner__content-wrap p {
    color: var(--cx-color);
    font-family: 'Rye', cursive;
}


@media screen and (max-width:550px) {
    .cx-servicebanner__wrap {
        padding: 15px;
        max-width: 95%;
    }
}