:root {
  --cx-color: #DDA8A8;
}

* {
  margin: 0;
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  color: #222222;
  font-size: 16px;
  line-height: 1.5625;
  letter-spacing: 0.5px;
  font-family: 'PT Sans Narrow', sans-serif;
}
a {
  text-decoration: none;
  color: inherit;
}
h1, h2, h3, h4, h5, h6 {
  font-family: 'Rye', cursive;
}
img {
  max-width: 100%;
}
.cx-page-width {
  margin: 0 auto;
  max-width: 1274px;
  padding-left: 20px;
  padding-right: 20px;
}
.cx-button {
  border: 0;
  border-radius: 0;
  padding: 0 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  min-height: 44px;
  margin: 10px 0;
  transition: all 0.2s ease;
}
.cx-button__pink {
  background: var(--cx-color);
  color: #111111;
}
.cx-button__pink:hover {
  background: #111111;
  color: var(--cx-color);
}
.cx-button__white {
  background: #FFFFFF;
  color: #111111;
}
.cx-button__white:hover {
  background: var(--cx-color);
}
input[type="text"],
input[type="number"],
input[type="email"],
input[type="phone"],
input[type="password"],
input[type="time"],
input[type="date"] {
  height: 50px;
  padding: 16px 24px;
  border: 0;
  border-radius: 0px;
  outline: 0px solid rgb(81 129 224 / 13%);
  color: #000000;
  background-color: #eaeaea;
  font-size: 18px;
  letter-spacing: 1px;
  box-shadow: 0 4px 10px rgb(81 129 224 / 7%);
  appearance: none;
  transition: 50ms;
  width: 100%;
  margin-bottom: 15px;
}
textarea {
  padding: 16px 24px;
  border: 0;
  border-radius: 0px;
  outline: 0px solid rgb(81 129 224 / 13%);
  color: #000000;
  background-color: #eaeaea;
  font-size: 18px;
  letter-spacing: 1px;
  box-shadow: 0 4px 10px rgb(81 129 224 / 7%);
  appearance: none;
  transition: 50ms;
  width: 100%;
  margin-bottom: 15px;
}
input[type="radio"],
input[type="checkbox"] {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

