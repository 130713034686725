.cx-footer {
    position: relative;
    padding: 25px 0;
}
.cx-footer__items-wrap {
    display: flex;
    flex-wrap: wrap;
}
.cx-footer__item {
    flex: 1 0 0;
    padding: 5px 15px 25px 15px;
}
.cx-footer__item h2 {
    margin-bottom: 10px;
}
.cx-footer__item ul {
    padding: 0;
    list-style-type: none;
}
.cx-footer__copy-wrap {
    text-align: center;
    border-top: 1px solid #3a3a3a;
    padding-top: 15px;
}
.cx-footer__social-wrap {
    display: flex;
    padding: 8px 0;
}
.cx-footer__social-item {
    padding: 10px 25px;
}
.cx-footer__social-item a {
    display: flex;
    justify-content: center;
    border-radius: 25px;
    background: var(--cx-color);
    color: #000000;
    align-items: center;
    width: 30px;
    height: 30px;
}
.cx-footer__phone-wrap {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 8px 0;
}
.cx-footer__phone-wrap a {
    display: inline-flex;
    align-items: center;
}
.cx-footer__phone-wrap svg {
    color: var(--cx-color);
    margin-right: 8px;
    margin-top: 3px;
}


@media screen and (max-width:550px) {
    .cx-footer__item {
        flex-basis: 100%;
    }
}