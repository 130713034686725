.cx-service {
    position: relative;
    padding: 55px 0;
}
.cx-service__full-width {
    position: relative;
}
.cx-service__wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.cx-service__wrap.cx-full-width {
    align-items: stretch;
}
.cx-service__wrap.cx-service-right {
    flex-direction: row-reverse;
}
.cx-service-left:not(.cx-full-width)>.cx-service__content-wrap {
    padding-left: 40px;
}
.cx-service-right:not(.cx-full-width)>.cx-service__content-wrap {
    padding-right: 40px;
}
.cx-service__content-wrap,
.cx-service__image-wrap {
    flex: 1 0 50%;
}
.cx-full-width .cx-service__content-wrap {
    padding: 45px 25px;
}
.cx-service__content-subtitle {
    color: var(--cx-color);
    margin-bottom: 10px;
    font-size: 14px;
}
.cx-service__content-wrap ul {
    padding-left: 18px; 
}
.cx-service__content-wrap p {
    color: var(--cx-color);
    font-family: 'Rye', cursive;
    margin: 10px 0;
}
.cx-full-width .cx-service__image {
    height: 100%;
    object-fit: cover;
}


@media screen and (max-width: 550px) {
    .cx-service__content-wrap, .cx-service__image-wrap {
        flex-basis: 100%;
    }
    .cx-service-left:not(.cx-full-width)>.cx-service__content-wrap {
        margin-top: 25px;
        padding-left: 0;
    }
    .cx-service-right:not(.cx-full-width)>.cx-service__content-wrap {
        padding-right: 0;
        margin-top: 25px;
    }
}