.cx-contact {
    position: relative;
    padding: 80px 0;
}
.cx-contact__wrap {
    max-width: 60%;
    display: flex;
    align-items: center;
    padding: 0;
    flex-wrap: wrap;
    margin: 0 auto;
}
.cx-contact__image-wrap,
.cx-contact__form-wrap {
    flex: 1 0 50%;
    padding: 20px;
}
.cx-contact__subtitle {
    font-size: 12px;
    margin-bottom: 20px;
    color: #DDA8A8;
}

/* CALENDAR STYLES */
.cx-contact .react-calendar,
.cx-contact .react-time-picker {
    width: 100%;
    margin-bottom: 15px;
}
.cx-contact .react-timerange-picker__inputGroup input {
    width: 0;
}


@media screen and (max-width: 550px) {
    .cx-contact {
        padding: 50px 0;
    }
    .cx-contact__wrap {
        max-width: 95%;
    }
}