.cx-page {
    padding: 45px 0;
}
.cx-page__title {
    text-align: center;
    font-size: 32px;
    margin-bottom: 40px;
}
.cx-page__wrapper ul {
    list-style-type: decimal;
}
.cx-page__wrapper ul li {
    font-size: 18px;
    margin-bottom: 10px;
}
