.cx-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
}
.cx-popup__wrapper {
    position: relative;
    text-align: center;
    width: 100%;
    max-width: 500px;
    padding: 20px;
}