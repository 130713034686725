.cx-banner {
    position: relative;
}
.cx-banner__overline {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
}
.cx-banner__image {
    width: 100%;
    height: 540px;
    object-fit: cover;
    vertical-align: bottom;
}
.cx-banner__content {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 70%;
    padding: 10px 20px;
    text-align: center;
}
.cx-banner__title {
    color: #DDA8A8;
    font-size: 62px;
    margin-bottom: 0;
}
.cx-banner__text {
    color: #FFFFFF;
    font-size: 22px;
    margin: 0 auto;
}
.cx-service__image-border {
    border: 5px solid var(--cx-color);
}

@media screen and (max-width: 550px) {
    .cx-banner__title {
        font-size: 30px;
    }
    .cx-banner__text {
        font-size: 16px;
    }
}