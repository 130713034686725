.cx-join {
    padding: 50px 0;
}
.cx-join__heading {
    font-size: 36px;
    text-align: center;
    margin-bottom: 35px;
}
.cx-join__text {
    font-size: 18px;
    margin-bottom: 20px;
}
.cx-join__title {
    text-align: center;
    border-bottom: 1px solid #222;
    margin: 25px 0;
    font-size: 28px;
}
.cx-join__input-group {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.cx-row-1 {
    width: 100%;
}
.cx-row-2 {
    width: 49%;
}
.cx-row-3 {
    width: 32%;
}
.cx-row-4 {
    width: 24%;
}
.cx-row-5 {
    width: 19%;
}
.cx-row-7 {
    width: 13.2%;
}
.cx-join__subtitle {
    width: 100%;
    margin-bottom: 10px;
}
.cx-join__radio-group {
    display: flex;
    margin-left: 30px;
}
.cx-join__radio-wrap:not(:first-of-type) {
    margin-left: 10px;
}
.cx-join__radio-wrap {
    margin-left: 20px;
    display: flex;
    align-items: center;
}
.cx-join__radio-wrap label {
    margin-left: 5px;
    font-size: 18px;
    cursor: pointer;
}
.cx-join__radio-container {
    display: flex;
    color: #000000;
    background-color: #eaeaea;
    justify-content: space-between;
    min-height: 50px;
    padding: 16px 24px;
    align-items: center;
    margin-bottom: 15px;
}
.cx-join__ques {
    font-size: 18px;
}
.cx-join__label-ques {
    width: 100%;
    font-size: 18px;
    margin-bottom: 5px;
}
.cx-join__checkbox-container {
    display: flex;
    color: #000000;
    background-color: #eaeaea;
    justify-content: space-between;
    min-height: 50px;
    padding: 16px 24px;
    align-items: center;
    margin-bottom: 15px;
    flex-wrap: wrap;
}
.cx-join__checkbox-wrap {
    display: flex;
    align-items: center;
}
.cx-join__checkbox-wrap label {
    margin-left: 10px;
    font-size: 18px;
    cursor: pointer;
}
.cx-join-checkbox__ques,
.cx-join-personal__ques {
    width: 100%;
    font-size: 18px;
    margin-bottom: 15px;
}
.cx-join-note__title {
    font-size: 23px;
    color: var(--cx-color);
    margin-bottom: 20px;
}
.cx-join__agree-p {
    font-size: 18px;
    font-weight: bold;
}
.cx-join__agree-wrap {
    margin-bottom: 40px;
}
.cx-join__agree-wrap .cx-join__radio-group {
    display: inline-flex;
    vertical-align: middle;
}
.cx-join__message {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
}
.cx-join__form-button-wrap {
    text-align: center;
}


@media screen and (max-width:550px) { 
    .cx-row-2,
    .cx-row-3 {
        width: 100%;
    }
    .cx-row-4,
    .cx-row-7,
    .cx-row-5 {
        width: 49%;
    }
    .cx-join__ques,
    .cx-join__text,
    .cx-join__agree-p {
        font-size: 16px;
    }
    .cx-join-note__title {
        font-size: 18px;
    }
    .cx-join__title {
        font-size: 24px;
    }
}