.cx-navbar {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 85px;
    padding: 20px 0;
    background: #000000;
    z-index: 999;
}
.cx-navbar__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.cx-navbar__menu {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style-type: none;
}
.cx-navbar__menu-item {
    position: relative;
    text-transform: uppercase;
    padding: 0 10px;
    color: #FFFFFF;
    font-size: 18px;
}
.cx-navbar__menu-item:hover .cx-navbar__child-menu {
    display: block;
}
.cx-navbar__menu-item:hover a.cx-have__child>svg {
    transform: rotate(180deg);
}
.cx-navbar__child-menu {
    display: none;
    min-width: 430px;
    position: absolute;
    top: 28px;
    left: 0;
    width: auto;
    height: auto;
    background: #000000;
    padding: 10px 20px;
    margin: 0;
    list-style-type: none;
    z-index: 999;
}
.cx-navbar__child-menu li {
    text-transform: none;
    color: #FFFFFF;
    font-size: 18px;
}
.cx-navbar__child-menu li a {
    display: block;
    padding: 5px 10px;
    transition: all 0.2s ease;
}
.cx-navbar__child-menu li a:hover {
    background-color: var(--cx-color);
    color: #000000;
}
.cx-navbar__child-menu li:last-child a {
    border-bottom: none;
}
a.cx-have__child {
    position: relative;
    padding-right: 20px;
}
a.cx-have__child>svg {
    position: absolute;
    right: 0;
    top: calc(50% - 9px);
    transition: all 0.2s ease;
}
a.cx-have__icon {
    color: var(--cx-color);
    font-weight: 600;
    vertical-align: super;
}
.cx-have__icon svg {
    vertical-align: middle;
    margin-right: 3px;
}
.cx__navbar-mobile-open {
    display: none;
    visibility: hidden;
    opacity: 0;
}


@media screen and (max-width: 768px) {
    .cx-navbar__wrap {
        justify-content: center;
    }
    .cx__navbar-mobile-open {
        display: block;
        visibility: visible;
        opacity: 1;
        position: absolute;
        top: calc(50% - 13px);
        left: 20px;
        background: unset;
        border: 0;
        padding: 0;
        color: #FFFFFF;
        font-size: 26px;
        height: 26px;
    }
    .cx-navbar__menu-wrap {
        position: fixed;
        left: -90%;
        top: 85px;
        transition: all 0.2s ease;
        width: 90%;
        height: calc(100vh - 85px);
        display: flex;
        background: #000;
        align-items: flex-start;
        padding: 40px 20px;
        overflow-y: auto;
    }
    .cx-navbar__open .cx-navbar__menu-wrap {
        left: 0;
    }
    .cx-navbar__menu {
        flex-direction: column;
        align-items: baseline;
    }
    .cx-navbar__menu-item {
        font-size: 20px;
        margin: 20px 0;
    }
    .cx-navbar__child-menu {
        display: block;
        min-width: unset;
        width: 100%;
        position: unset;
        padding: 5px 0;
        border-top: 1px solid #DEDEDE;
        border-bottom: 1px solid #DEDEDE;
    }
    .cx-navbar__child-menu li a {
        padding: 3px 5px;
    }
    a.cx-have__child>svg {
        display: none;
        visibility: hidden;
        opacity: 0;
    }
}